import React, { memo, useMemo, useState, useContext } from "react";
import {
  DataGridPro,
  getGridNumericOperators,
  getGridStringOperators,
} from "@mui/x-data-grid-pro";
import CustomFilter from "components/Reports/CustomFilter";
import {
  convertGridColumnMenu,
  convertStageCodeToName,
  formatDate,
  reformatString,
  delay,
  numberWithCommas
} from "helpers/ReusableFunctions";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { PitchResponseChoices } from "helpers/Constant";
import { UserContext } from "context/UserContext";
import { maximumExportPaging } from "helpers/Constant";
import ModalBox from "components/Modal/ModalBox";
import ExportModal from "pages/PipelineReport/components/ExportModal";

const renderSocialLinksActions = (params) => {
  return (
    <Link to={params.value} target="_blank" rel="noreferrer">
      {params.value}
    </Link>
  );
};

const PitchedCandidatesTab = ({
  apiRef = null,
  language,
  isInternalUser,
  loading = false,
  pitchedCandidates = [],
  pagination = {},
  onPaginationModelChange = {},
  onRowClick = () => {},
  onShowMatchModal = () => {},
}) => {
  const { t } = useTranslation();
  const [showExportModal, setShowExportModal] = useState(false);
  const { userGroup, loggedInUserData } = useContext(UserContext);

  const sortableColumn = true;
  const pinnableColumn = true;

  const filterOperators = getGridStringOperators().filter(
    ({ value }) => !["isEmpty", "isNotEmpty", "isAnyOf"].includes(value)
  );

  const customStageColumnSorting = (v1, v2, ...rest) => {
    let row1_id = rest[0].id;
    let row2_id = rest[1].id;

    let row1 = rest[0].api.getRow(row1_id);
    let row2 = rest[1].api.getRow(row2_id);

    let intV1 = v1 !== "New Candidate" ? +row1?.[rest[0].field] : 0;
    let intV2 = v2 !== "New Candidate" ? +row2?.[rest[0].field] : 0;
    return intV1 - intV2;
  };

  const japaneseName = useMemo(
    () => [
      {
        field: "kanji_last",
        headerName: t("performanceReportText.lastNameJ"),
        width: 150,
        sortable: sortableColumn,
        pinnable: pinnableColumn,
        filterOperators: filterOperators,
      },
      {
        field: "kanji_first",
        headerName: t("performanceReportText.firstNameJ"),
        width: 150,
        sortable: sortableColumn,
        pinnable: pinnableColumn,
        filterOperators: filterOperators,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [language]
  );

  const englishName = useMemo(
    () => [
      {
        field: "first_name",
        headerName: t("performanceReportText.firstName"),
        width: 150,
        sortable: sortableColumn,
        pinnable: pinnableColumn,
        filterOperators: filterOperators,
      },
      {
        field: "last_name",
        headerName: t("performanceReportText.lastName"),
        width: 150,
        sortable: sortableColumn,
        pinnable: pinnableColumn,
        filterOperators: filterOperators,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [language]
  );

  const columns = useMemo(
    () => [
      {
        field: "esai_score",
        headerName: "ESAI Score",
        width: 150,
        sortable: sortableColumn,
        pinnable: pinnableColumn,
        filterOperators: filterOperators,
        type: "number",
        align: "left",
        headerAlign: "left",
      },
      {
        field: "esai_match",
        headerName: "ESAI Match",
        width: 150,
        sortable: sortableColumn,
        pinnable: pinnableColumn,
        filterOperators: filterOperators,
        renderCell: (params) => {
          if (params?.value) {
            return (
              <Link onClick={(e) => onShowMatchModal(e, params?.row)}>
                View
              </Link>
            );
          }
          return "";
        },
      },
      ...(isInternalUser
        ? language === "ja"
          ? japaneseName
          : englishName
        : []),
      {
        field: "current_employer",
        headerName: t("performanceReportText.employer"),
        width: 150,
        sortable: sortableColumn,
        pinnable: pinnableColumn,
        filterOperators: filterOperators,
      },
      {
        field: "current_title",
        headerName: t("performanceReportText.title"),
        width: 200,
        sortable: sortableColumn,
        pinnable: pinnableColumn,
        filterOperators: filterOperators,
      },
      {
        field: "estimated_english",
        headerName: t("performanceReportText.estimatedEnglish"),
        width: 200,
        sortable: sortableColumn,
        pinnable: pinnableColumn,
        filterOperators: filterOperators,
      },
      {
        field: "estimated_japanese",
        headerName: t("performanceReportText.estimatedJapanese"),
        width: 200,
        sortable: sortableColumn,
        pinnable: pinnableColumn,
        filterOperators: filterOperators,
      },
      {
        field: "estimated_birth_year",
        headerName: t("performanceReportText.estimatedBirthYear"),
        width: 200,
        sortable: sortableColumn,
        pinnable: pinnableColumn,
        filterOperators: filterOperators,
      },
      {
        field: "estimated_salary",
        headerName: t("performanceReportText.estimatedSalary"),
        width: 200,
        sortable: sortableColumn,
        pinnable: pinnableColumn,
        filterOperators: filterOperators,
        renderCell: ({ value }) => value ? numberWithCommas(value) : "",
      },
      {
        type: "string",
        field: "current_stage",
        headerName: t("performanceReportText.latestStage"),
        width: 150,
        sortable: sortableColumn,
        pinnable: pinnableColumn,
        filterOperators: filterOperators,
        valueGetter: ({ row }) =>
          convertStageCodeToName(
            row?.app_position?.current_stage,
            row?.app_position?.pitch_response.response
          ),
        sortComparator: (v1, v2) => {
          const intV1 = customStageColumnSorting;
          const intV2 = customStageColumnSorting;
          return intV1 - intV2;
        },
      },
      {
        field: "last_activity_date",
        headerName: t("performanceReportText.lastActivity"),
        width: 200,
        sortable: sortableColumn,
        pinnableColumn: pinnableColumn,
        filterOperators: filterOperators,
        renderCell: ({ value }) => {
          return formatDate(value);
        },
      },
      {
        field: "initial_response",
        headerName: t("performanceReportText.initialResponse"),
        width: 200,
        sortable: sortableColumn,
        pinnable: pinnableColumn,
        filterOperators: filterOperators,
        valueGetter: ({ row }) =>
          reformatString(
            PitchResponseChoices[row?.app_position?.pitch_response?.response]
          ),
      },
      ...(isInternalUser
        ? [
            {
              field: "response_reason",
              headerName: t("barChartText.reason"),
              width: 200,
              sortable: sortableColumn,
              pinnable: pinnableColumn,
              filterOperators: filterOperators,
            },
          ]
        : []),
      {
        field: "salary",
        headerName: t("performanceReportText.salary"),
        width: 150,
        sortable: sortableColumn,
        pinnable: pinnableColumn,
        filterOperators: filterOperators,
        type: "number",
        align: "left",
        headerAlign: "left",
        renderCell: ({ value }) => value ? numberWithCommas(value) : "",
      },
      {
        field: "salary_breakdown",
        headerName: t("performanceReportText.salaryBreakdown"),
        width: 150,
        sortable: sortableColumn,
        pinnable: pinnableColumn,
        filterOperators: filterOperators,
      },
      {
        field: "english",
        headerName: t("performanceReportText.english"),
        width: 150,
        sortable: sortableColumn,
        pinnable: pinnableColumn,
        filterOperators: filterOperators,
        type: "number",
        align: "left",
        headerAlign: "left",
      },
      {
        field: "japanese",
        headerName: t("performanceReportText.japanese"),
        width: 150,
        sortable: sortableColumn,
        pinnable: pinnableColumn,
        filterOperators: filterOperators,
        type: "number",
        align: "left",
        headerAlign: "left",
      },
      ...(isInternalUser
        ? [
            ...(language === "en" ? japaneseName : englishName),
            {
              field: "linkedin_url",
              headerName: t("performanceReportText.linkedinUrl"),
              width: 150,
              sortable: sortableColumn,
              pinnable: pinnableColumn,
              filterOperators: filterOperators,
              renderCell: renderSocialLinksActions,
            },
            {
              field: "twitter_url",
              headerName: t("performanceReportText.twitterUrl"),
              width: 150,
              sortable: sortableColumn,
              pinnable: pinnableColumn,
              filterOperators: filterOperators,
              renderCell: renderSocialLinksActions,
            },
            {
              field: "facebook_url",
              headerName: t("performanceReportText.facebookUrl"),
              width: 150,
              sortable: sortableColumn,
              pinnable: pinnableColumn,
              filterOperators: filterOperators,
              renderCell: renderSocialLinksActions,
            },
            {
              field: "github_url",
              headerName: t("performanceReportText.githubUrl"),
              width: 150,
              sortable: sortableColumn,
              pinnable: pinnableColumn,
              filterOperators: filterOperators,
              renderCell: renderSocialLinksActions,
            },
          ]
        : []),
      {
        field: "age",
        headerName: t("performanceReportText.age"),
        width: 150,
        sortable: sortableColumn,
        pinnable: pinnableColumn,
        filterOperators: filterOperators,
      },
      {
        field: "education_history",
        headerName: t("performanceReportText.educationHistory"),
        width: 200,
        sortable: sortableColumn,
        pinnable: pinnableColumn,
        filterOperators: filterOperators,
      },
      {
        field: "work_history",
        headerName: t("performanceReportText.workHistory"),
        width: 500,
        sortable: sortableColumn,
        pinnable: pinnableColumn,
        filterOperators: filterOperators,
      },
    ],

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [englishName, japaneseName]
  );

  const [columnsOrder, setColumnsOrder] = useState(columns);

  const columnsWithOperators = columnsOrder.map((col) => {
    if (col.type === "number") {
      return {
        ...col,
        filterOperators: [
          ...getGridNumericOperators()
            .filter((operator) => {
              if (operator.value === "=") operator.label = "equals";
              if (operator.value === ">") operator.label = "greater than";
              if (operator.value === "<") operator.label = "less than";
              return (
                operator.value === ">" ||
                operator.value === "<" ||
                operator.value === "="
              );
            })
            .map((operator) => ({
              ...operator,
              InputComponent: CustomFilter,
            })),
        ],
      };
    }

    const customFilterOperators = getGridStringOperators().map((operator) => ({
      ...operator,
      InputComponent: CustomFilter,
    }));

    return {
      ...col,
      filterOperators: customFilterOperators,
    };
  });

  const handleShowExportModal = () => {
    setShowExportModal((current) => !current);
  };

  const handleExport = async () => {
    await onPaginationModelChange(maximumExportPaging);
    await apiRef.current.setPaginationModel(maximumExportPaging);
    await delay(1000);
  };

  return (
    <>
      <div className="container job-details-card">
        <div className="cstm-mui-datagrid">
          <div className="d-flex justify-content-between align-items-center flex-wrap">
            <h5 className="flex-shrink-1">{""}</h5>
            <div className="d-flex gap-0 flex-shrink-0">
              {(userGroup.includes("external") ||
                (userGroup.includes("internal") &&
                  loggedInUserData.is_admin)) && (
                <div
                  className="action-button align-self-center"
                  onClick={handleShowExportModal}
                >
                  <div className="m-0">{t("performanceReportText.export")}</div>
                </div>
              )}
            </div>
          </div>
          <DataGridPro
            loading={loading}
            rows={pitchedCandidates}
            rowCount={pagination?.count}
            pagination
            pageSizeOptions={[50, 100, 500, 1000]}
            onPaginationModelChange={onPaginationModelChange}
            columns={columnsWithOperators}
            slotProps={{
              filterPanel: {
                sx: { maxWidth: "calc(90vw - 24px)" },
              },
              pagination: {
                backIconButtonProps: {
                  disabled: loading || !pagination?.offset,
                },
                nextIconButtonProps: {
                  disabled: loading || !pagination?.hasMore,
                },
              },
            }}
            getRowId={(row) => row?.id}
            onColumnOrderChange={(c) => {
              setColumnsOrder((prevCols) => {
                const newCols = [...prevCols];
                newCols.splice(c.oldIndex, 1);
                newCols.splice(c.targetIndex, 0, c.column);
                return newCols;
              });
            }}
            initialState={{
              ...columns.initialState,
              columns: columns.initialState?.columns,
              pagination: { paginationModel: { pageSize: 1000, page: 0 } },
            }}
            onRowClick={(params) => onRowClick(params?.row)}
            localeText={convertGridColumnMenu(language)}
            apiRef={apiRef}
            rowsLoadingMode={"server"}
          />
        </div>
      </div>
      <ModalBox
        show={showExportModal}
        onHide={handleShowExportModal}
        title="Export File"
        content={
          <ExportModal
            fileName="Pitched Candidates"
            setShowModal={setShowExportModal}
            columns={columnsOrder}
            apiRef={apiRef}
            // hiddenColumns={hiddenColumns}
            onExport={handleExport}
          />
        }
      />
    </>
  );
};

export default memo(PitchedCandidatesTab);
